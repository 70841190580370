.inner-service-image {
    border-radius: 10px;
}

.inner-service-title {
    margin: 0px;
    font-size: 30px;
}

.subtitle {
    margin: 0px;
    font-size: 20px;
    color: var(--dark);
}

.inner-service-body {
    font-size: 18px;
    line-height: 25px;
    width: 80%;
}

@media only screen and (max-width:767px) {
    .inner-service-body {
        font-size: 16px;
        line-height: 25px;
        width: 100%;
    }    
    .inner-service-image {
        border-radius: 10px;
        width: 100%;
        height: auto;
    }
    .inner-service-title {
        margin: 0px;
        font-size: 25px;
    }
    .subtitle {
        margin: 0;
        font-size: 20px;
    }
} 