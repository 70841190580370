.hero-text {
    font-size: 80px;
    line-height: 80px;
    text-align: center;
}

.hero-body {
    font-size: 20px;
    line-height: 20px;
}

.hero-row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-section {
    /* background: url('../../../public//images/orange-blur.svg'); */
    background-repeat: no-repeat;
    background-size: contain;
    color: black;
    display: flex;
    align-items: center;
   
}

.hero-section h1 {
    background:  -webkit-radial-gradient(var(--light), var(--dark));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

.pulse-bg {
    background: url('../../../public/images/pulse.gif');
    background-repeat: no-repeat;
    background-position: bottom 0px right 0px;
    background-size: contain;
}

.radial-bg {
    background: url('../../../public/images/pulse.gif');
    background-repeat: no-repeat;
    background-position: bottom 0px left 0px;
    background-size: contain;
}

.landing-section {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('../../../public//images/triangle.webp');
}

.hero-image {
    width: 500px;
    height: 500px;
}

.hero-body {
    width: 65%;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
}

@media only screen and (max-width:767px) {
    .hero-section {
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;

    }
    .hero-section h1 {
        font-size: 55px;
        line-height: 55px;
    }
    .landing-section {
        margin-top: 70px;
        padding: 15px;
    }
    .hero-image {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    .hero-body {
        width: 100%;
    }
  }