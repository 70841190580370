.page-header {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public//images//about-bg.jpg');
}

.page-title {
    font-size: 100px;
    line-height: 140px;
    margin: 0;
}

.page-tagline {
    font-size: 40px;
    line-height: 50px;
    margin: 0;
    font-weight: 400;
}

.value-card {
    box-shadow: 1px 5px 10px 2px rgba(5, 89, 138, 0.6);
    border-radius: 10px;
    padding: 20px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
}

.value-gif {
    width: 150px;
    height: 150px;
    object-fit: contain;
}

.values-row {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-top: 10px;
}

.value-title {
    font-size: 25px;
    margin: 0;
}

@media only screen and (max-width:767px) {
    .values-row {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
    }
    .value-card {
        height: auto !important;
    }
    .page-title {
        font-size: 55px;
        line-height: 60px;
        margin: 0;
    }
    .page-tagline {
        font-size: 20px;
        line-height: 30px;
    }
    .page-header {
        height: 30vh !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-image: url('../../../public//images//about-bg.jpg');
    }
    
}

.vis-mis-bg {
    background: linear-gradient(110deg, var(--dark) 60%, #9ae7df 50%);
    padding-top:50px;
    padding-bottom: 50px;
}

.vis-mis-individual {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
}

.vis-mis-individual h4 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
}

.vis-mis-individual p {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    line-height: 25px;
}
