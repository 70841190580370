.cta-row {
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    background-color: #e2e2ea;
    height: 300px;
    justify-content: center;
}

.cta-row h4 {
    font-size: 25px;
    font-weight: 400;
    margin: 0;
    text-align: center;
}

.cta-button {
    /* width: 200px; */
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    background-color: var(--dark);
    border: none;
    border-radius: 5px;
    padding: 20px 40px;
    font-size: 18px;
    cursor: pointer;

}



@media only screen and (max-width:767px) {
    .cta-button {
        padding: 10px 20px;
        font-size: 17px;

    }
  }
  


/* f9f9fb */