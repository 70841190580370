.menu-bar {
    display: flex;
    justify-content: space-around;
    column-gap: 20px;
    width: 100%;
    align-items: center;
}

.web-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-menu {
    font-size: 25px;
    margin: 0px;
    color: var(--dark);
    font-weight: 700;
}
.inactive-menu {
    color: var(--dark);
    font-size: 20px;
    margin: 0px;
}
.inactive-menu:hover {
    font-size: 25px;
    margin: 0px;
    color: var(--dark);
    font-weight: 700;
}

.logo {
    width: 150px;
    height: auto;
}

.nav-wrapper {
    display: flex;
    width: 1300px;
    padding-top: 10px;
    padding-bottom: 10px;

}
.mobile-nav-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    align-items: center;
    height: 60px;
    position: fixed;
    z-index: 2;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
}




.mobile-menu-container {
    background-color: var(--dark);
    min-height: 100vh;
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    background-image: url('../../../public//images//contact-bg.svg');
    background-repeat: no-repeat;
    background-position: bottom -0 right -100px;
    background-size: contain;
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 60px;
}

.mobile-menu-button {
    background: #f5f5f7;
    /* padding: 15px; */
    border: none;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    display: none;
}
.mobile-menu-button h4 {
    margin: 0;
}

.menu-icon {
    margin: 0;
    font-size: 30px;
    color: var(--dark);
}

@media only screen and (max-width:767px) {
    .logo {
        width: 100px;
        height: auto;
    }
    .active-menu {
        font-size: 18px;
        color: var(--orange);
        font-weight: 700;
    }
    .inactive-menu {
        color: #fff;
        font-size: 18px;
    }
    .inactive-menu:hover {
        font-size: 18px;
        margin: 0px;
        color: var(--orange);
        font-weight: 700;
    }
}