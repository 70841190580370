.project-desc-container {
    /* background-color: var(--dark); */
    padding: 20px;
}

.project-title {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    color: var(--dark);
    margin-bottom: 10px;
}

.project-desc {
    font-size: 18px;
    font-weight: 400;
    color: #333;
    margin: 0;
    margin-bottom: 10px;
}

.project-button {
    border-radius: 10px;
    color: #fff;
    font-weight: 600;
    background-color: var(--dark);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    
}

.project-card {
    box-shadow: 1px 5px 10px 2px rgba(5, 89, 138, 0.6);
    border-radius: 10px;
    margin: 20px 0px
}

.project-image {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

@media only screen and (max-width:767px) {
    .project-desc {
        font-size: 16px;
    }
}