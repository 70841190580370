.page-header-bg {
    height: 300px;
    background-color: #9ae7df;
    background-image: 
    url('/public/images/blue-blur.svg'),
    url('/public/images/orange-blur.svg');
    background-size:auto;
    background-repeat: no-repeat;
    background-position: 
    top 0px left 0px,
    bottom 0px right 0px; 
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.inner-page-title {
    font-size: 45px;
    margin: 0px;
    padding-left: 15px;
}

@media only screen and (max-width:767px) {
    .page-header-bg { 
        margin-top: 70px;
    }
  }