.page-header {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public//images//contact-bg.jpg');
}

.contact-item-row {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.button-items-row {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #fff;
}

.button-icon {
    width: 40px;
    height: 40px;
    color: #fff;
}

.contact-item-row p {
    font-size: 20px;
    margin: 0;
    line-height: 40px;
    color: white;
}

.contact-icon {
    color: var(--orange);
    font-size: 25px;
}

.contact-item-row-animation {
     width: 50px;
     height: 50px;
}

.contact-icons-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 50px;
}

.contact-form-label {
    font-size: 20px;
    color: #d4d4d4;
    font-weight: 600;
}

.form-input {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0px;
    border: none;
    border-bottom: solid 2px #d4d4d4;
    outline: none;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;

}
.form-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.message-input {
    width: 100%;
    min-height: 300px;
    box-sizing: border-box;
    padding: 15px 0px;
    border: none;
    border-bottom: solid 2px #d4d4d4;
    outline: none;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'aeonik';
    font-size: 18px;

}

.submit-row {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.submit-button {
    width: auto;
    border-radius: 10px;
    padding: 15px 40px;
    border: none;
    background: var(--dark);
    color: white;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}
.double-bg {
    background: linear-gradient(90deg, #fff 50%, var(--dark) 50%);
}


.contact-half-column {
    width: 50%;
    padding: 50px;
}

.contact-bg-white {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
}

.contact-details-card {
    background-color: var(--dark);
    padding: 40px;
    border-radius: 10px;
    width: 40%;
    background-image: url('../../../public//images//contact-bg.svg');
    background-repeat: no-repeat;
    background-position: bottom -150px right -100px;
    background-size: contain;

}

.details-image {
    bottom: -150px;
    right: -100px;
}

.contact-form-area {
    width: 60%;
    padding: 40px;
}

.contact-details-title {
    font-size: 25px;
    font-weight: 700;
    margin: 0;
    color: white;
}

.contact-details-tagline {
    font-size: 18px;
    line-height: 22px;
    color: #d4d4d4;
}



.py-50 {
    padding: 50px 0px;
}


@media only screen and (max-width:767px) {
    .contact-bg-white {
        flex-direction: column;
    }
    .contact-details-card {
        padding: 20px;
        padding-bottom: 300px;
        width: auto;
    }
    .contact-form-area {
        width: auto;
        padding: 20px;
    }
    .py-50 {
        padding: 50px 15px;
    }
    .contact-item-row p {
        font-size: 16px;
        margin: 0;
        line-height: 20px;
        color: white;
    }
    .contact-form-label {
        font-size: 17px;
    }
    .form-input {
        font-size: 16px;
    }
    .message-input {
        font-size: 16px;
    }
}

@media only screen and (min-width:767px) {
    .wp-50 {
        padding: 50px;
    }

}


.pointer {
    cursor: pointer;
}

.sent-animation {
    width: 300px;
    height: auto;
}