.footer-row {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-column-gap: 30px;
}
.footer-bg {
    /* background: url('../../../public//images/footer-bg.jpg') rgba(0, 0, 0, 0.7); */
    /* background-blend-mode: multiply; */
    padding-top: 70px;
    padding-bottom: 70px;
    min-height: 300px;
    background-position: center;
    background-size: cover;
    background-color: #282828;
}

.footer-title {
    font-size: 30px;
    color: white;
    font-weight: 700;
    margin: 0px;
}

.footer-link {
    margin: 0;
    font-size: 16px;
    color: white;
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
}

.mr-10 {
    margin-right: 10px;
}

.footer-text {
    font-size: 16px;
    color: white;
    line-height: 25px;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media only screen and (max-width:767px) {
    .footer-row {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .footer-bg {
        padding: 40px 15px;
    }
    .footer-title {
        font-size: 25px;
    }

}
