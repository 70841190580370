.two-col-container {
    display: flex;
    grid-template-columns: 50% 50%;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
    column-gap: 50px;
}
.half-column {
    width: 50%;
}
.body-container {
    width: 90%;
}

.body-container p {
    font-size: 18px;
    line-height: 30px;
}


@media only screen and (max-width:767px) {
    .two-col-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
    }
    .half-column {
        width: 100%;
        margin-bottom: 10px;
    }
    .body-container {
        width: 100%;
    }
    
}



.full-screen {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inner-page-full-screen {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-bg {
    background-color: var(--light);
    padding-top: 70px;
    padding-bottom: 70px;
    /* background-image: 
    url('/public/images/settings.png'),
    url('/public/images/cooling.png');
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: 
    top 0px right 0px,
    bottom 0px right; */
    /* background-position: bottom 0px left 0px; */
    
}



.repair-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.about-button {
    background-color: transparent;
    border: solid 2px var(--dark);
    color: var(--dark);
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    padding: 15px 20px;
    border-radius: 10px;
    cursor: pointer;

}
.about-icon-container {
    background: var(--dark);
    color: var(--light);
    padding: 20px;
}

.about-button:hover {
    background-color: var(--dark);
    /* border: solid 3px var(--dark); */
    /* padding: 20px; */
    color: var(--light);
    font-size: 16px;
    font-weight: 900;
}
.about-button:hover .about-icon-container {
    background: var(--light);
    color: var(--dark);
    padding: 20px;
}


@media only screen and (max-width:767px) {
    .repair-image {
        width: 100% !important;
        height: auto;
        border-radius: 10px;
    }
  }


  .about-icon-container {
    background: #e2e2ea;
    padding: 20px;
}
