.services-row {
    display: flex;
    column-gap: 20px;
    /* justify-content: center; */
}

.section-title {
    font-size: 45px;
    font-weight: 700;
    line-height: 45px;
    margin: 0;
    text-align: center;
    margin-bottom: 70px;
}

.service-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.service-link {
    background: #f5f5f7;
    /* padding: 15px; */
    border: none;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.service-icon-container {
    background: #e2e2ea;
    padding: 20px;
}

.service-link h4 {
    margin: 0;
    color: #000;
}

.service-title-container {
    padding: 10px 20px;
}

.page-link-button {
    border: none;
    padding:20px;
    width: 300px;
    background-color: var(--light);
}

.flex-end {
    display: flex;
    justify-content: end;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.mt-30 {
    margin-top: 30px;
}
.flex-start {
    display: flex;
    justify-content: start;

}

.service-card {
    margin: 5px 10px;
    padding: 20px 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 10px 7px 10px -6px #777;
    cursor: pointer;
    border: solid 1px var(--dark);
}

.service-card:hover {
    background-color: var(--dark);
}
.service-card:hover h4 {
    color: #fff;
}
.service-card:hover p {
    color: #fff;
}




.grey-bg {
    background-color: #e2e2ea;
    width: 100%;
}

@media only screen and (max-width:767px) {
    .services-row {
        flex-direction: column;
    }
    .section-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 45px;
    }
    .grey-bg {
        background-color: #e2e2ea;
        width: auto;
    }
}
.text-center {
    text-align: center;
}

.w-100 {
    width: 100% !important;
}

.text-blue {
    color: var(--dark);
}

.text-black {
    color: #000 ;
}

.learn-more {
    font-size: 18px;
}