body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --light: #9ae7df;
  --dark: #05598a;
  --orange: #FF6701;
  font-family: 'aeonik';
  font-weight: 400;
}

@font-face {
  font-family: "aeonik";
  src: url('./fonts/Aeonik-Regular.otf');

  font-weight: 400;
}


/* @font-face {
  font-family: "aeonik";
  src: url('./fonts/Aeonik-Bold.otf');
  font-weight: 700;
} */

a {
  text-decoration: none;
}

.max-width {
  width: 1300px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-right {
  display: flex;
  justify-content: right;
}



@media only screen and (min-width:767px) {
  .mobile-only {
      display: none !important;
  }
}

@media only screen and (max-width:767px) {
  .desktop-only {
      display: none !important;
  }
  .flex-reverse {
    flex-direction: column-reverse !important;
  }
  .max-width {
    width: 100%;
  }
  .mp-15 {
    padding: 15px;
  }
}

.my-20 {
   margin-top: 20px;
   margin-bottom: 20px;
}


.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mb-20 {
  margin-bottom: 20px;
}